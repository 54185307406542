.simulation-list-container {
    max-width: 1200px;
    margin: 60px auto;
    padding: 0 20px;
}

.section-title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 40px;
    text-align: center;
}

.simulation-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
}

.simulation-card {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.simulation-card:hover {
    transform: translateY(-5px);
}

.video-link {
    position: relative;
    display: block;
}

.simulation-thumbnail {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: 2em;
    padding: 10px 20px;
    border-radius: 50%;
}

.simulation-info {
    padding: 20px;
}

.simulation-info h3 {
    font-size: 1.4em;
    color: #333;
    margin-bottom: 10px;
}

.simulation-info p {
    color: #666;
    font-size: 0.9em;
    line-height: 1.6;
    margin-bottom: 20px;
}

.button-group {
    display: flex;
    gap: 10px;
}

.primary-button, .secondary-button {
    flex: 1;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.primary-button {
    background-color: #1e3c72;
    color: white;
}

.primary-button:hover {
    background-color: #2a5298;
}

.secondary-button {
    background-color: #f0f0f0;
    color: #333;
}

.secondary-button:hover {
    background-color: #e0e0e0;
}

.create-button {
    display: block;
    width: 250px;
    margin: 40px auto 0;
    padding: 15px 30px;
    background-color: #4CAF50;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.1em;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.create-button:hover {
    background-color: #45a049;
}

.placeholder-thumbnail {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coming-soon-text {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2em;
}