.level-editor {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.editor-layout {
    display: flex;
    gap: 20px;
}

.canvas-container {
    flex: 3;
}

.controls {
    flex: 1;
}

button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

button:hover {
    background-color: #45a049;
}

.object-properties {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.object-properties label {
    display: block;
    margin-bottom: 10px;
}

.object-properties input {
    width: 100%;
    padding: 5px;
    margin-top: 5px;
}