.main-header {
    background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
    color: white;
    padding: 20px 0;
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.logo {
    text-decoration: none;
    color: white;
}

.logo h1 {
    font-size: 2em;
    margin: 0;
    font-family: 'Orbitron', sans-serif;
}

nav {
    display: flex;
    gap: 20px;
}

.nav-link {
    color: white;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s;
}

.nav-link:hover {
    color: #4da6ff;
}