.simulation-container {
    max-width: 1200px;
    margin: 60px auto;
    padding: 0 20px;
}

.simulation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.simulation-button {
    width: 100%;
    margin-bottom: 20px;
    padding: 15px 20px;
    font-size: 1.1em;
    background-color: #4CAF50;  /* Green color, matching edit level buttons */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
}

.simulation-button:hover {
    background-color: #45a049;  /* Darker shade for hover state */
}

.simulation-canvas {
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}